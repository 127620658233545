import { GTMEvents } from 'constants/enums';

export const NAV_MENU_LINKS = [
  {
    name: 'Home',
    route: '/',
    GTMEvent: GTMEvents.HomeClick
  },
  {
    name: 'About us',
    route: '/about',
    GTMEvent: GTMEvents.AboutUsClick
  },
  {
    name: 'For Employers',
    route: '/employers',
    GTMEvent: GTMEvents.EmployersPageClick
  }
];
