import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import cs from 'classnames';

import NavMenu from 'components/NavMenu/NavMenu';

import { GTMEventLogger } from 'utils/GTMEventLogger';

import { GTMEvents } from 'constants/enums';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';

import styles from './Header.module.scss';

const Header = () => {
  const location = useLocation();
  const coloredBgPaths = ['/', '/employers'];

  const headerClass = cs(styles.header, {
    [styles.coloredBg]: coloredBgPaths.includes(location.pathname)
  });

  useEffect(() => {
    GTMEventLogger(GTMEvents.SessionStart);
  }, []);

  return (
    <header className={headerClass}>
      <NavLink
        to="/"
        end
        className={styles.logo}
        onClick={() => GTMEventLogger(GTMEvents.HomeClick)}
      >
        <Logo />
      </NavLink>
      <NavMenu />
    </header>
  );
};

export default Header;
