import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Modal } from 'antd';
import cs from 'classnames';

import { useMobileDesign } from 'hooks/useMobileDesign';

import { GTMEventLogger } from 'utils/GTMEventLogger';

import { GTMEvents } from 'constants/enums';

import { ReactComponent as CloseIcon } from 'assets/icons/close-modal.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as DottedButton } from 'assets/icons/navmenu-button.svg';

import styles from './NavMenu.module.scss';
import { NAV_MENU_LINKS } from './config';

const NavMenu = () => {
  const isMobile = useMobileDesign();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const handleMenuItemClick = (GTMEvent: GTMEvents) => {
    GTMEventLogger(GTMEvent);
    handleMobileMenuClose();
  };

  const links = NAV_MENU_LINKS.map((link) => (
    <NavLink
      key={link.name}
      to={link.route}
      end={link.route === '/'}
      className={({ isActive }) =>
        cs(styles.link, { [styles.active]: isActive })
      }
      onClick={() => handleMenuItemClick(link.GTMEvent)}
    >
      {link.name}
    </NavLink>
  ));

  return (
    <>
      {isMobile ? (
        <button
          className={styles.menuButton}
          onClick={handleMobileMenuOpen}
          title="Open menu"
        >
          <DottedButton />
        </button>
      ) : (
        <nav className={styles.nav}>{links}</nav>
      )}
      {isMobileMenuOpen && (
        <Modal
          open={isMobileMenuOpen}
          onCancel={handleMobileMenuClose}
          footer={null}
          className={styles.container}
          width="100%"
          closeIcon={<CloseIcon />}
          destroyOnClose
        >
          <Logo className={styles.logo} />
          <nav className={styles.navMobile}>{links}</nav>
        </Modal>
      )}
    </>
  );
};

export default NavMenu;
